footer {
  background: #242424;
  display: flex;
  flex-direction: column;
  padding: 56px 0;

  @media (max-width: 768px) {
    padding: 30px 0 50px;
  }

  .footer-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .ltd {
      @media (max-width: 768px) {
        order: 2;
      }
    }

    span {
      color: #979797;
      font-size: 14px;
      font-weight: 400;
    }

    .contacts {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: 768px) {
        order: 1;
      }

      p {
        color: #FFF;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        margin: 0;
        text-align: center;
      }

      .social {
        display: flex;
        gap: 15px;

        a {
          width: 24px;
          height: 24px;

          &.in {
            background: url("../img/in.svg") no-repeat center;
            background-size: 100%;
          }

          &.fb {
            background: url("../img/fb.svg") no-repeat center;
            background-size: 100%;
          }

          &.instagram {
            background: url("../img/insta.svg") no-repeat center;
            background-size: 100%;
          }

          &.tiktok {
            background: url("../img/tiktok.svg") no-repeat center;
            background-size: 100%;
          }

          &.telegram {
            background: url("../img/telegram.svg") no-repeat center;
            background-size: 100%;
          }
        }
      }
    }
  }
}


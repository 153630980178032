@charset "utf-8";
@import 'fonts.scss';
@import 'header.scss';
@import 'footer.scss';
@import 'animation.scss';

$font-family: Inter,
serif;

html {
  /* For Firefox */
  overflow-y: scroll;
  scrollbar-width: thin;
}

html, body {
  font-family: $font-family;
  color: #242424;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;

}

* {
  box-sizing: border-box;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.w-1500-center {
  width: 100%;
  max-width: 1532px;
  padding: 0 16px;
  margin: 0 auto;
}

.empty {
  @media (max-width: 1024px) {
    display: none;
  }
}

.mt-100-adapt {
  margin-top: 100px;

  @media (max-width: 480px) {
    margin-top: 60px;
  }
}

.pt-100-adapt {
  padding-top: 100px;

  @media (max-width: 480px) {
    padding-top: 60px;
  }
}

.account-link-anchor {
  background: #55D086;
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding: 20px;
  color: #ffffff;
  display: inline-block;
  text-decoration: none;
  min-width: 260px;
  text-align: center;
}

.invest-container {
  position: relative;
  width: 100%;
  margin-top: 140px;
  padding-top: 60px;
  border-top: 1px solid #E2E8F0;

  @media (max-width: 768px) {
    margin-top: 100px;
  }

  @media (max-width: 600px) {
    margin-top: 120px;
  }

  .invest-block {
    max-width: 1172px;
    margin: 0 auto;
    position: relative;
    height: 750px;
    padding: 0 16px;

    @media (max-width: 991px) {
      height: 450px;

      &.mob-height {
        height: 670px;
      }
    }

    @media (max-width: 768px) {
      height: 550px;

      &.mob-height {
        height: 670px;
      }
    }

    .invest-block-info {
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: center;

      h1 {
        color: #000;
        text-align: center;
        font-size: 80px;
        font-weight: 700;
        line-height: 96px;
        margin: 0;

        @media (max-width: 991px) {
          font-size: 48px;
          line-height: 56px;
        }

        @media (max-width: 768px) {
          font-size: 36px;
          line-height: 44px;
        }
      }

      h5 {
        color: #000;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
}

.companies-container {
  position: relative;
  padding: 80px 45px 0 45px;

  @media (max-width: 1200px) {
    padding: 40px 16px 0 16px;
  }

  @media (max-width: 768px) {
    padding-top: 0;
  }

  .companies-block {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    min-height: 750px;
    gap: 100px;
    justify-content: center;

    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 768px) {
      gap: 40px;
      min-height: unset;
    }

    .text-block {
      display: flex;
      justify-content: flex-end;

      .text {
        display: flex;
        flex-direction: column;
        gap: 36px;
        max-width: 640px;
        justify-content: right;
        width: 100%;

        @media (max-width: 1200px) {
          align-items: center;
        }

        @media (max-width: 768px) {
          align-items: center;
          gap: 20px;
        }

        h2 {
          color: #28353D;
          font-size: 48px;
          font-weight: 800;
          line-height: 58px;
          margin: 0;

          @media (max-width: 768px) {
            font-size: 32px;
            line-height: 38px;
            text-align: center;
          }
        }

        p {
          color: #28353D;
          font-size: 24px;
          font-weight: 400;
          line-height: 28px;
          margin: 0;

          @media (max-width: 768px) {
            font-size: 16px;
            line-height: 22px;
            text-align: center;
          }
        }

        a {
          color: #FFF;
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          border-radius: 10px;
          background: #55D086;
          padding: 18px 24px;
          min-width: 264px;
          text-decoration: none;
          display: inline-block;
          text-align: center;
        }
      }
    }

    .img {
      img {
        width: 100%;
        max-width: 880px;

        @media (max-width: 1200px) {
          max-width: 640px;
        }
      }
    }
  }
}

.numbers-info-container {
  display: flex;
  width: 100%;
  padding: 45px;

  @media (max-width: 768px) {
    padding: 45px 20px;
  }

  .numbers-info-block {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    background: #3A55F7;
    gap: 160px;
    padding: 60px;

    @media (max-width: 1300px) {
      gap: 80px;
    }

    @media (max-width: 991px) {
      gap: 60px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 768px) {
      gap: 30px;
      padding: 40px;
    }

    .numbers-info-item {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 16px;
      justify-content: center;

      @media (max-width: 768px) {
        gap: 8px;
        justify-content: flex-start;
      }

      h3 {
        color: #FFF;
        font-size: 64px;
        font-weight: 700;
        line-height: 70px;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 30px;
          line-height: 38px;
        }
      }

      h6 {
        color: #FFF;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}

.how-to-start-container {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  gap: 40px;

  h2 {
    color: #28353D;
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    line-height: 54px;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 48px;
    }
  }

  .user-steps {
    width: 100%;
    display: flex;
    gap: 140px;
    justify-content: center;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 60px;
    }

    .step {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      gap: 20px;

      img {
        height: 85px;
        object-fit: contain;
      }

      .sign-up {
        display: block;
        text-align: center;

        a, p {
          display: inline;
        }
      }

      a {
        color: #477EAD;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        text-decoration-line: underline;

      }

      p {
        color: #28353D;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        margin: 0;
      }
    }
  }
}

.why-weltrade-container {
  margin-top: 100px;
  display: flex;
  gap: 50px;
  flex-direction: column;
  padding-bottom: 40px;

  @media (max-width: 1300px) {
    padding: 70px 30px;
    margin-top: 50px;
  }

  @media (max-width: 768px) {
    padding: 40px 0;
    gap: 30px;
  }

  h2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 68px;
    text-align: center;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 48px;
      font-weight: 600;
    }
  }

  .benefits-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 32px;

    @media (max-width: 1300px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    .benefit-item {
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-radius: 40px;
      background: #F8F8FB;
      padding: 30px;

      @media (max-width: 768px) {
        padding: 20px;
      }

      h3 {
        color: #3A55F7;
        font-size: 50px;
        font-weight: 500;
        line-height: 32px;
        margin: 0;
        padding: 10px 0 35px;
      }

      img {
        height: 80px;
        width: 100%;
        object-fit: contain;
        object-position: left;

        @media (max-width: 768px) {
          height: 60px;
        }
      }

      p {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 34px;
        margin: 0;
        padding-top: 15px;

        @media (max-width: 768px) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}

.clients-reviews-container {
  margin-top: 80px;
  margin-bottom: 40px;
  padding: 60px 0;
  background: #E6E8F5;

  .clients-reviews-block {
    display: flex;
    flex-direction: column;
    gap: 50px;

    h2 {
      color: #000;
      font-size: 48px;
      font-weight: 600;
      line-height: 68px;
      margin: 0;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 36px;
        line-height: 48px;
      }
    }

    .reviews-list {
      width: 100%;

      .review-item {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px 20px 26px 20px;
        border-radius: 10px;
        background: #FFF;
        box-shadow: -2px 2px 6px 0px rgba(0, 0, 0, 0.10), -8px 9px 12px 0px rgba(0, 0, 0, 0.09), -18px 19px 16px 0px rgba(0, 0, 0, 0.05), -31px 34px 19px 0px rgba(0, 0, 0, 0.01), -49px 54px 20px 0px rgba(0, 0, 0, 0.00);
        width: 100%;
        text-decoration: none;

        @media (max-width: 991px) {
          box-shadow: none;
        }

        img {
          height: 20px;
          width: auto;
        }

        h3 {
          color: #000;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin: 0;
        }

        p {
          color: #000;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          margin: 0;

          &.font-12 {
            font-size: 12px;
            line-height: 14px;
          }
        }

        h4 {
          display: flex;
          color: #000;
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          margin: 0;

          span {
            font-weight: 500;
          }
        }
      }
    }

    .swiper {
      padding: 40px;

      @media (max-width: 991px) {
        padding: 0;
      }

      .swiper-wrapper {
        @media (min-width: 991px) {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          gap: 27px;
        }
      }
    }

    .swiper-arrows {
      position: relative;
      justify-content: center;
      gap: 10px;
      display: none;

      @media (max-width: 991px) {
        display: flex;
      }

      .arrow {
        position: relative;
        width: 50px;
        height: 50px;
        left: unset;
        right: unset;

        &:hover {
          path {
            &:first-child {
              fill: #242424;
            }

            &:last-child {
              fill: #ffffff;
            }
          }
        }
      }

      .arrow {
        -webkit-tap-highlight-color: transparent;
      }

      .swiper-button-prev::after, .swiper-button-next::after {
        content: unset;
      }

      .swiper-button-prev::after, .swiper-button-next::after {
        content: unset;
      }
    }
  }
}

.regulated-broker-container {
  display: grid;
  padding: 90px 0;
  grid-template-columns: 1fr 1fr;
  gap: 50px;

  @media (max-width: 768px) {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
  }

  .img {
    width: 100%;
    overflow: hidden;

    @media (max-width: 768px) {
      order: 2;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .benefits-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 460px;

    @media (max-width: 768px) {
      order: 1;
    }

    h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 58px;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 30px;
        line-height: 38px;
      }
    }

    .benefit-item {
      padding-left: 72px;
      background: url('/img/flag.png') no-repeat left top;
      min-height: 60px;
      background-size: 60px;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
        padding-left: 50px;
        background-size: 40px;
      }
    }
  }
}

#regForm {
  width: 100%;
  display: flex;
  padding-bottom: 100px;

  @media (max-width: 768px) {
    padding-bottom: 0;
  }

  .form-frame {
    width: 100%;
    border: none;
    height: 580px;

    @media (max-width: 768px) {
      height: 520px;
    }
  }
}

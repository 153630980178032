.header {
  position: absolute;
  width: 100%;
  top: 45px;
  left: 0;
  z-index: 10;

  @media (max-width: 768px) {
    top: 28px;
  }

  @media (max-width: 600px) {
    top: 16px;
  }

  &.is-sticky {
    top: 0;
    position: fixed;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #262626;
    animation: slideDown 0.35s ease-out;

    .logo {
      .sticky {
        display: block;
      }

      .main {
        display: none;
      }
    }

    @media (max-width: 600px) {
      .buttons {
        display: none!important;
      }
    }
  }
}

.header__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1532px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;

  @media (max-width: 550px) {
    gap: 8px;
    align-items: flex-start;
  }


  .logo {
    display: flex;
    align-items: flex-end;

    img {
      width: 170px;
      object-fit: contain;

      &.sticky {
        display: none;
      }

      @media (max-width: 480px) {
        width: 141px;
      }
    }
  }

  .buttons-wrap {
    @media (max-width: 600px) {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
}

.years {

  align-items: center;
  margin-left: 16px;
  display: none;
  @media screen and (min-width: 768px) {
    margin-left: 50px;
    display: flex;
  }
  &__number {
    background: linear-gradient(180deg, #4A65F8 0%, #292929 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 48px;
    line-height: 48px;
    font-weight: 600;
    @media screen and (min-width: 768px) {
      font-size: 64px;
      line-height: 52px;
    }
  }
  &__text-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
    @media screen and (min-width: 480px) {
      margin-left: 8px;
      padding-top: 8px;
    }
  }
  &__text {
    font-size: 8px;
    line-height: 9px;
    @media screen and (min-width: 768px) {
      font-size: 15px;
      line-height: 17px;
    }
  }
  &__text_bold {
    font-weight: 700;
  }
}

.header.is-sticky .years__text {
  color: #FFF;
}

.is-sticky .dropdown {
  display: none;
}

.dropbtn {
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 10;
  color: var(--light-color);

  @media screen and (min-width: 550px) {
    margin-top: 0;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background: #FFFFFF;
  border-radius: 10px;
  width: 225px;
  -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
  right: 0;
  top: 40px;
  padding: 10px 0;

  @media screen and (min-width: 550px) {
    right: 0;
    left: auto;
    transform: translateX(0);
  }

  &.active {
    display: block;
  }

  @media (max-width: 480px) {
    padding: 5px 0;
  }

  &:after {
    content: "";
    position: absolute;
    border: 8px solid rgba(0,0,0,0);
    top: -15px;
    border-bottom: 8px solid #fff;
    right: 30px;
    left: auto;
    transform: translateX(0);
  }
}

.dropbtn {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #242424;
  border-radius: 5px;
  padding: 0 15px;
  display: flex;
  align-items: center;

  &:before {
    width: 36px;
    height: 36px;
    display: inline-block;
    background-size: 18px 18px !important;
    background-color: #F5F5F5 !important;
    border-radius: 50px;
  }

  &:after {
    content: "";
    background: url("../img/arrow.svg") no-repeat center;
    width: 24px;
    height: 24px;
  }
}

.dropdown-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #2E4453;
  padding: 13px 30px 13px 24px;
  text-decoration: none;
  background-size: 18px 18px!important;
  gap: 24px;

  &:before {
    width: 18px;
    height: 18px;
    border-radius: 50px;
  }

  &.active {
    color: #93AFC8;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    background-color: #F2F4F5;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.en:before {
  content: "";
  background: url("../img/flags/en.svg") no-repeat center;
  background-size: 100%;
}

.es:before {
  content: "";
  background: url("../img/flags/es.svg") no-repeat center;
  background-size: 100%;
}

.id:before {
  content: "";
  background: url("../img/flags/id.svg") no-repeat center;
  background-size: 100%;
}

.km:before {
  content: "";
  background: url("../img/flags/km.svg") no-repeat center;
  background-size: 100%;
}

.lo:before {
  content: "";
  background: url("../img/flags/lo.svg") no-repeat center;
  background-size: 100%;
}

.ms:before {
  content: "";
  background: url("../img/flags/ms.svg") no-repeat center;
  background-size: 100%;
}

.ru:before {
  content: "";
  background: url("../img/flags/ru.svg") no-repeat center;
  background-size: 100%;
}

.th:before {
  content: "";
  background: url("../img/flags/th.svg") no-repeat center;
  background-size: 100%;
}

.vi:before {
  content: "";
  background: url("../img/flags/vi.svg") no-repeat center;
  background-size: 100%;
}

.zh:before {
  content: "";
  background: url("../img/flags/zh.svg") no-repeat center;
  background-size: 100%;
}

.in:before {
  content: "";
  background: url("../img/flags/in.svg") no-repeat center;
  background-size: 100%;
}

.ph:before {
  content: "";
  background: url("../img/flags/ph.svg") no-repeat center;
  background-size: 100%;
}


.buttons {
  display: flex;
  gap: 18px;

  @media (max-width: 600px) {
    position: absolute;
    left: 16px;
    top: 54px;
  }

  a {
    padding: 10px 14px;
    border-radius: 5px;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-decoration: none;
  }

  .log {
    background: #05A46E;

    @media (max-width: 600px) {
      order: 2;
    }
  }

  .reg {
    background: #3A55F7;

    @media (max-width: 600px) {
     order: 1;
    }
  }
}

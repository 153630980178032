.animation-container {
  position: absolute;
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: 0;
  top: 0;
}

.animation-item {
  width: 100px;
  height: 100px;
  position: absolute !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF;
  z-index: 2;
  box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.07), -3px 8px 9px 0px rgba(0, 0, 0, 0.06), -8px 18px 12px 0px rgba(0, 0, 0, 0.03), -14px 32px 14px 0px rgba(0, 0, 0, 0.01), -21px 49px 15px 0px rgba(0, 0, 0, 0.00);
  padding: 8px;

  .icon {
    width: 100%;
  }

  @media (max-width: 991px) {
    width: 40px;
    height: 40px;

    .icon {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media (min-width: 992px) {
  .animation-item {
    position: absolute !important;

    &--apple {
      top: 60%!important;
      left: 100%!important;
    }

    &--gold {
      top: 30% !important;
      left: -25% !important;
    }

    &--oil {
      top: 70% !important;
      left: -15% !important;
    }

    &--meta {
      top: 43% !important;
      left: -5% !important;
    }

    &--nike {
      top: 80%!important;
      left: 123%!important;
    }

    &--spotify {
      top: 79% !important;
      left: 53% !important;
    }

    &--tesla {
      top: 80% !important;
      left: 11% !important;
    }

    &--google {
      top: 62% !important;
      left: 31% !important;
    }

    &--netflix {
      top: 33%!important;
      left: 113%!important;
    }

    &--intel {
      top: 45%!important;
      left: 85%!important;
    }

    &--microsoft {
      top: 59%!important;
      left: 69%!important;
    }

    &--twitter {
      top: 0% !important;
      left: -16% !important;
    }

    &--paypal {
      top: -2% !important;
      left: 104% !important;
    }

    &--visa {
      top: 77%!important;
      left: 85%!important;
    }
  }
}

@media (max-width: 991px) {
  .animation-item {
    &--apple {
      top: 63%!important;
      left: 70%!important;
    }

    &--gold {
      top: 7%!important;
      left: -3%!important;
    }

    &--oil {
      top: 75%!important;
      left: 2%!important;
    }

    &--meta {
      top: 63%!important;
      left: 8%!important;
    }

    &--nike {
      top: 66%!important;
      left: 85%!important;
    }

    &--spotify {
      top: 75%!important;
      left: 46%!important;
    }

    &--tesla {
      top: 80%!important;
      left: 25%!important;
    }

    &--google {
      top: 67%!important;
      left: 30%!important;
    }

    &--netflix {
      top: 8%!important;
      left: 89%!important;
    }

    &--intel {
      top: 76%!important;
      left: 65%!important;
    }

    &--microsoft {
      top: 64%!important;
      left: 49%!important;
    }

    &--twitter {
      top: -7%!important;
      left: 8%!important;
    }

    &--paypal {
      top: -7%!important;
      left: 82%!important;
    }

    &--visa {
      top: 84%!important;
      left: 80%!important;
    }
  }
}
